import { DATE_CUTOFFS, DateUnitEnum, TIME_UNITS } from 'shared/constants/date-time'
import { LocaleEnum } from 'shared/enums/locale-enum'

/**
 * A fallback function to format relative time in a human-readable string
 * when `Intl.RelativeTimeFormat` is unavailable in the browser. Only in English is supported.
 *
 * @internal This function is not intended for external use.
 * @function fallbackRelativeTimeFormat
 * @returns {string} A string representing how much time has passed or how much is remaining.
 *
 * @example
 * // Example 1: Past time, 1 day ago
 * fallbackRelativeTimeFormat(-1, 'day', { numeric: 'always', style: 'long' })
 * // => "1 day ago"
 *
 * @example
 * // Example 2: Future time, 5 weeks, short style
 * fallbackRelativeTimeFormat(5, 'week', { numeric: 'always', style: 'short' })
 * // => "in 5 wks"
 *
 * @example
 * // Example 3: numeric='auto', 1 day in the future => "tomorrow"
 * fallbackRelativeTimeFormat(1, 'day', { numeric: 'auto' })
 * // => "tomorrow"
 */
const fallbackRelativeTimeFormat = (
  valueInUnits: number,
  unit: Intl.RelativeTimeFormatUnit | keyof typeof DateUnitEnum,
  options: {
    numeric?: 'always' | 'auto'
    style?: 'long' | 'short' | 'narrow'
    locale?: string
  } = {},
): string => {
  const { numeric = 'auto', style = 'long' } = options

  const isFuture = valueInUnits > 0
  const absValue = Math.abs(valueInUnits)

  const unitLabels: Record<
    string,
    { long: [string, string]; short: [string, string]; narrow: [string, string] }
  > = {
    second: {
      long: ['second', 'seconds'],
      short: ['sec', 'secs'],
      narrow: ['s', 's'],
    },
    minute: {
      long: ['minute', 'minutes'],
      short: ['min', 'mins'],
      narrow: ['min', 'mins'],
    },
    hour: {
      long: ['hour', 'hours'],
      short: ['hr', 'hrs'],
      narrow: ['h', 'h'],
    },
    day: {
      long: ['day', 'days'],
      short: ['day', 'days'],
      narrow: ['d', 'd'],
    },
    week: {
      long: ['week', 'weeks'],
      short: ['wk', 'wks'],
      narrow: ['w', 'w'],
    },
    month: {
      long: ['month', 'months'],
      short: ['mo', 'mos'],
      narrow: ['mo', 'mos'],
    },
    year: {
      long: ['year', 'years'],
      short: ['yr', 'yrs'],
      narrow: ['y', 'y'],
    },
  }

  const unitKey = String(unit) as keyof typeof unitLabels
  if (!unitLabels[unitKey]) {
    return isFuture ? `in ${absValue} ${unit}(s)` : `${absValue} ${unit}(s) ago`
  }

  // Select according to style
  const labelForms = unitLabels[unitKey][style]
  // Define singular/plural number
  const label = absValue === 1 ? labelForms[0] : labelForms[1]

  if (numeric === 'auto') {
    if (unitKey === 'day' && absValue === 1) {
      return isFuture ? 'tomorrow' : 'yesterday'
    }
    if (unitKey === 'second' && absValue < 30) {
      return 'just now'
    }
  }

  if (isFuture) {
    return `in ${absValue} ${label}`
  } else {
    return `${absValue} ${label} ago`
  }
}

interface TimeSinceOptions {
  locale?: LocaleEnum
  /** Whether to always use numeric values or allow phrases like "yesterday" (default is 'auto'). */
  numeric?: 'always' | 'auto'
  style?: 'long' | 'short' | 'narrow'
  unit?: keyof typeof DateUnitEnum
}

/**
 * Calculates the time difference from the given date to the current date
 * and formats it into a human-readable string. If no unit is provided, the function
 * will automatically determine the most appropriate unit based on the time difference.
 *
 * @example
 * // Default usage
 * const timeAgo = timeSince(new Date(Date.now() - 86400000)); // 1 day ago
 * console.log(timeAgo); // "1 day ago"
 *
 * @example
 * // Using custom options
 * const customTimeAgo = timeSince(new Date(Date.now() - 3600000), {
 *   locale: LocaleEnum.FRENCH,
 *   numeric: 'always',
 *   style: 'short',
 * });
 * console.log(customTimeAgo); // "il y a 1 h"
 *
 * @example
 * // Specify a unit
 * const customUnitTimeAgo = timeSince(new Date(Date.now() - 60000), { unit: 'minute' });
 * console.log(customUnitTimeAgo); // "1 minute ago"
 *
 * @example
 * // Automatically determine unit
 * const autoUnitTimeAgo = timeSince(new Date(Date.now() - 31536000000));
 * console.log(autoUnitTimeAgo); // "1 year ago"
 */
export const timeSince = (date: Date, options: TimeSinceOptions = {}): string => {
  const { locale = LocaleEnum.ENGLISH, numeric = 'auto', style = 'long', unit } = options

  const targetTime = new Date(date).getTime()
  const referenceTime = new Date().getTime()

  const deltaSeconds = Math.round((targetTime - referenceTime) / 1000)

  const isRelativeTimeFormatSupported =
    typeof Intl !== 'undefined' && typeof Intl.RelativeTimeFormat !== 'undefined'

  // If a specific unit is provided, use it directly
  if (unit) {
    const delta = deltaSeconds / DateUnitEnum[unit]

    if (!isRelativeTimeFormatSupported) {
      return fallbackRelativeTimeFormat(delta, unit, { numeric, style, locale })
    }

    const rtf = new Intl.RelativeTimeFormat(locale, { numeric, style })
    return rtf.format(Math.round(delta), unit)
  }

  // Determine the appropriate unit dynamically
  const unitIndex = DATE_CUTOFFS.findIndex(cutoff => cutoff > Math.abs(deltaSeconds))
  const divisor = unitIndex ? DATE_CUTOFFS[unitIndex - 1] : 1
  const finalValue = Math.floor(deltaSeconds / divisor)
  const finalUnit = TIME_UNITS[unitIndex].unit

  if (!isRelativeTimeFormatSupported) {
    return fallbackRelativeTimeFormat(finalValue, finalUnit, { numeric, style, locale })
  }

  const rtf = new Intl.RelativeTimeFormat(locale, { numeric, style })
  return rtf.format(finalValue, finalUnit)
}
