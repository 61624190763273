import { TimeUnits } from 'shared/types/time-units'

export const SHORT_TIME_LOCO: Record<TimeUnits, string> = {
  s: 'global.time.seconds_short',
  m: 'global.time.minutes_short',
  h: 'global.time.hours_short',
  d: 'global.time.days_short',
  w: 'global.time.weeks_short',
  mo: 'global.time.months_short',
  y: 'global.time.years_short',
}
