import { SHORT_TIME_LOCO } from 'shared/constants/short-time-loco'
import { TimeUnits } from '../types/time-units'

/**
 * Enum representing date units and their corresponding duration in seconds, not milliseconds.
 */
export enum DateUnitEnum {
  second = 1,
  minute = 60,
  hour = 60 * 60,
  day = 60 * 60 * 24,
  week = 60 * 60 * 24 * 7,
  month = 60 * 60 * 24 * 30,
  year = 60 * 60 * 24 * 365,
}

/**
 * A unified constant that maps each time unit to its `Intl.RelativeTimeFormatUnit`
 * and corresponding label. This structure allows for consistent handling of relative
 * time calculations and localized label access.
 *
 * The **order** of items in this array must remain consistent with `DATE_CUTOFFS`.
 * Any changes to this order can result in mismatched units or incorrect calculations.
 *
 * @important
 * The order of this array is tied directly to `DATE_CUTOFFS`. Ensure that both
 * constants are updated together if modifications are required.
 */
export const TIME_UNITS: {
  unit: Intl.RelativeTimeFormatUnit
  shortKey: TimeUnits
  shortLabel: (typeof SHORT_TIME_LOCO)[TimeUnits]
}[] = [
  {
    unit: 'second' as Intl.RelativeTimeFormatUnit,
    shortKey: 's',
    shortLabel: 'comments.components.comments.just_now_label',
  },
  {
    unit: 'minute' as Intl.RelativeTimeFormatUnit,
    shortKey: 'm',
    shortLabel: SHORT_TIME_LOCO.m,
  },
  {
    unit: 'hour' as Intl.RelativeTimeFormatUnit,
    shortKey: 'h',
    shortLabel: SHORT_TIME_LOCO.h,
  },
  {
    unit: 'day' as Intl.RelativeTimeFormatUnit,
    shortKey: 'd',
    shortLabel: SHORT_TIME_LOCO.d,
  },
  {
    unit: 'week' as Intl.RelativeTimeFormatUnit,
    shortKey: 'w',
    shortLabel: SHORT_TIME_LOCO.w,
  },
  {
    unit: 'month' as Intl.RelativeTimeFormatUnit,
    shortKey: 'mo',
    shortLabel: SHORT_TIME_LOCO.mo,
  },
  {
    unit: 'year' as Intl.RelativeTimeFormatUnit,
    shortKey: 'y',
    shortLabel: SHORT_TIME_LOCO.y,
  },
]

/**
 * An array of sequential time thresholds, each representing the upper limit (in seconds)
 * for a corresponding time unit (e.g., minute, hour, day, etc.). This array is directly
 * related to the `TIME_UNITS` constant and is used to determine the most appropriate
 * unit for a given time difference.
 *
 * **Important:** The order of items in this array is critical for determining which unit
 * to use for a given time difference. Changing the order or values will break the mapping
 * logic in related functions.
 *
 * Example usage:
 * ```ts
 * const deltaInSeconds = Math.abs(targetTime - currentTime) / 1000;
 * const unitIndex = DATE_CUTOFFS.findIndex((cutoff) => cutoff > deltaInSeconds);
 * const { unit, shortKey } = TIME_UNITS[unitIndex];
 * console.log(`The appropriate unit is: ${unit} (${shortKey})`);
 * ```
 */
export const DATE_CUTOFFS = [
  DateUnitEnum.minute,
  DateUnitEnum.hour,
  DateUnitEnum.day,
  DateUnitEnum.week,
  DateUnitEnum.month,
  DateUnitEnum.year,
  Infinity,
] as const
